<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_fw">年度经营数据</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'YearManage'"
            :title="'统计季度：'"
            :introductionType="'quarter'"
            :width="'100'"
            :clearable="true"
            :list="quarterGenerate"
            :yearValue="sectionInfo.yearManageYear"
            v-model="sectionInfo.yearManageQuarter"
            @change="onYearManageChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'YearManage'"
            :introductionType="'quarter'"
            :width="'100'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.yearManageQuarter"
            v-model="sectionInfo.yearManageYear"
            @change="onYearManageChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onYearManageImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        :summary-method="getSummaries"
        show-summary
        max-height="450"
        border
        :data="yearManageList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="公司"
          sortable
          align="center"
          fixed="left"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.company | dict(dictData.companyType) }}
          </template></el-table-column
        >
        <el-table-column prop="projectCount" sortable min-width="100" align="center">
          <template slot-scope="{}" slot="header">
            <span>立项数</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>已立项的商务数</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.projectCount }}
          </template></el-table-column
        >
        <el-table-column prop="salesRevenue" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>销售金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额（销售合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.salesRevenue | applyAmount }}
          </template></el-table-column
        >
        <el-table-column prop="invoicedAmount" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>开票金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已开票金额（销售合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.invoicedAmount | applyAmount }}
          </template></el-table-column
        >

        <el-table-column prop="receivedAmount" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已收款金额（销售合同）</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.receivedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="procurement" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>采购</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额带出（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.procurement | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedInvoices" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>收票</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已开票金额（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedInvoices | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="payments" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>付款</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已收款金额（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.payments | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="businessExpend"
          sortable
          min-width="130"
          label="商务报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.businessExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectExpend"
          sortable
          min-width="130"
          label="项目报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.projectExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="publicSpendingExpend"
          sortable
          min-width="130"
          label="公共报销"
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.publicSpendingExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="reimbursements" sortable min-width="150" align="right">
          <template slot-scope="{}" slot="header">
            <span>总报销</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务报销 + 项目报销 + 公共报销</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.reimbursements | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualReceipts" sortable min-width="130" align="right">
          <template slot-scope="{}" slot="header">
            <span>实际收款</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目收入中收款金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualReceipts | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="actualExpenditure" min-width="150" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>实际支出</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>费用报销 + 费用付款 + 人工支出 + 税费 + 外协生产费用 + 设备折旧</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualExpenditure | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="fundBalance" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>资金余额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>实际收款 - 实际支出</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.fundBalance | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalRevenue" min-width="150" sortable label="总收入" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalRevenue | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalCost" min-width="150" sortable label="总成本" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalCost | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalProfit" min-width="150" sortable label="总利润" align="right">
          <template slot-scope="scope">
            {{ scope.row.totalProfit | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">公司月度财务数据</span>
        <span class="title_right"
          ><SearchUserDate
            :type="'MonthFinance'"
            :introductionType="'month'"
            :width="'100'"
            :clearable="true"
            :list="monthGenerate"
            :yearValue="sectionInfo.monthFinanceYear"
            v-model="sectionInfo.monthFinanceMonth"
            @change="onMonthFinanceChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :width="'100'"
            :type="'MonthFinance'"
            :introductionType="'month'"
            :yearIsShow="true"
            :list="dictData.yearList"
            :twoValue="sectionInfo.monthFinanceMonth"
            v-model="sectionInfo.monthFinanceYear"
            @change="onMonthFinanceChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onMonthFinanceImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table
        :summary-method="getMnthFinance"
        show-summary
        max-height="450"
        border
        :data="monthFinanceList"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          fixed="left"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="公司"
          sortable
          align="center"
          fixed="left"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.company | dict(dictData.companyType) }}
          </template></el-table-column
        >
        <el-table-column prop="salesRevenue" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>合同金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.salesRevenue | applyAmount }}
          </template></el-table-column
        >
        <el-table-column prop="receivedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>回款金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已收款金额</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.receivedAmount | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="invoicedAmount" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应收额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务所创建的项目，</div>
                <div>项目收入中的已开票金额</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.invoicedAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="procurement" min-width="130" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>采购额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>项目详情里的合同实际金额带出（采购合同）</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.procurement | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column
          prop="businessExpend"
          min-width="130"
          label="商务报销"
          sortable
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.businessExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectExpend"
          min-width="130"
          label="项目报销"
          sortable
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.projectExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="publicSpendingExpend"
          min-width="130"
          label="公共报销"
          sortable
          align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.publicSpendingExpend | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="reimbursements" min-width="150" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>报销额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>商务报销 + 项目报销 + 公共报销</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.reimbursements | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column prop="receivedInvoices" min-width="150" sortable align="right">
          <template slot-scope="{}" slot="header">
            <span>应付额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>按发起时间算起的所有报销 + 付款申请金额</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.receivedInvoices | applyAmount }}
          </template>
        </el-table-column>
        <el-table-column
          prop="actualExpenditure"
          min-width="150"
          label="实际支出"
          sortable
          align="right"
        >
          <template slot-scope="{}" slot="header">
            <span>实际支出</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>费用报销 + 费用付款 + 人工支出 + 税费 + 外协生产费用 + 设备折旧</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope">
            {{ scope.row.actualExpenditure | applyAmount }}
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card class="box-card" style="margin-top: 8px">
      <div slot="header" class="clearfix">
        <span class="title_fw">季度新增商务数据表</span>
        <span class="title_right"
          ><SearchUserDate
            :title="titleYear"
            :type="'AddBusiness'"
            :width="'100'"
            :yearIsShow="true"
            :list="dictData.yearList"
            v-model="sectionInfo.addBusinessYear"
            @change="onAddBusinessChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="onAddBusinessImg"
          >数据来源说明</el-button
        >
      </div>
      <el-table max-height="450" border :data="addBusinessList">
        <el-table-column
          prop="quarters"
          label="季度"
          align="center"
          min-width="100"
        ></el-table-column>
        <el-table-column
          prop="newBusinessCount"
          min-width="130"
          label="新增商务数量"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.newBusinessCount }}
          </template></el-table-column
        >
        <el-table-column prop="newProjectCount" min-width="120" align="center">
          <template slot-scope="{}" slot="header">
            <span>新增项目数量</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>为新商务已创建的项目数量</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.newProjectCount }}
          </template>
        </el-table-column>
        <el-table-column prop="totalProjectAmount" min-width="120" align="right">
          <template slot-scope="{}" slot="header">
            <span>新增项目总金额</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>为新商务已创建的项目，项目收入合计</div>
              </div>
            </el-tooltip></template
          ><template slot-scope="scope">
            {{ scope.row.totalProjectAmount | applyAmount }}
          </template>
        </el-table-column>

        <el-table-column prop="growthRate" min-width="120" align="right">
          <template slot-scope="{}" slot="header">
            <span>环比增长率</span>
            <el-tooltip class="item" effect="dark" placement="top">
              <i class="el-icon-question" style="font-size: 16px"></i>
              <div slot="content">
                <div>当前期与上一期相比的增长率。</div>
                <div>（当前期数 - 上一期数） / 上一期数 * 100</div>
              </div>
            </el-tooltip></template
          >
          <template slot-scope="scope"> {{ scope.row.growthRate }}% </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          :src="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/${imgUrl}.png?${new Date().getTime()}`"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      dictData: {
        yearList: [],
        companyType: [],
      },
      yearManageList: [], // 年度经营数据
      monthFinanceList: [], // 公司月度财务数据
      addBusinessList: [], // 季度新增商务数据表
      sectionInfo: {
        // 条件筛选
        yearManageYear: null, // 年份
        monthFinanceYear: null,
        addBusinessYear: null,
        yearManageQuarter: '', // 季度
        monthFinanceMonth: null, // 月份
      },
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
      projectPaymentAmount: 0,
      projectPaymentAmountC: 0,
      projectPaymentAmountMnth: 0,
      projectPaymentAmountCMnth: 0,
    }
  },
  provide() {
    return {}
  },
  computed: {
    quarterGenerate() {
      const arr = []
      for (let index = 0; index < 4; index++) {
        arr.push({ id: index + 1, dictName: `第${index + 1}季度`, dictVal: `Q${index + 1}` })
      }
      arr.unshift({
        id: '0',
        dictName: '全部',
        dictVal: '',
      })
      return arr
    },
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  filters: {
    applyAmount(n) {
      if (n) {
        if (n === null || n === undefined) return ''
        // 转换为数字并保留两位小数
        let number = parseFloat(n).toFixed(2)
        // 增加千分位
        let parts = number.split('.')
        let integerPart = parts[0]
        let decimalPart = parts[1]
        // 使用正则表达式添加千分位
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return `${integerPart}.${decimalPart}`
      } else {
        return n
      }
    },
  },
  watch: {},
  mounted() {},
  methods: {
    init(val) {
      this.loading = true
      Promise.all([
        this.$api.statistics.queryAnnualExecutiveData(`date=${val}`),
        this.$api.statistics.queryMonthlyFinanceReport(`date=${val}`),
        this.$api.statistics.queryNewBusinessDataQuarterly(`date=${val}`),
      ])
        .then(res => {
          // 年度经营数据
          this.yearManageList = res[0].data.annualExecutiveDataView.annualExecutiveDataVoList || []
          this.projectPaymentAmount = res[0].data.annualExecutiveDataView.projectPaymentAmount || 0
          this.projectPaymentAmountC =
            res[0].data.annualExecutiveDataView.projectPaymentAmountC || 0
          this.monthFinanceList = res[1].data.monthlyFinanceDataView.monthlyFinanceDataVoList || []
          this.projectPaymentAmountMnth =
            res[1].data.monthlyFinanceDataView.projectPaymentAmount || 0
          this.projectPaymentAmountCMnth =
            res[1].data.monthlyFinanceDataView.projectPaymentAmountC || 0
          // 由前端根据选择年份去过滤数据
          const arr = res[2].data.quarterlyBusinessDataVoList || []
          this.addBusinessList = arr.filter(v => v.year == val)

          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    onAddBusinessImg() {
      this.imgUrl = '季度新增商务数据表'
      this.isDialog = true
    },
    onYearManageImg() {
      this.imgUrl = '年度经营数据'
      this.isDialog = true
    },
    onMonthFinanceImg() {
      this.imgUrl = '公司月度财务数据'
      this.isDialog = true
    },
    onAddBusinessChange(arr) {
      this.addBusinessList = arr
    },
    onYearManageChange(obj) {
      this.yearManageList = obj.annualExecutiveDataVoList || []
      this.projectPaymentAmount = obj.projectPaymentAmount || 0
      this.projectPaymentAmountC = obj.projectPaymentAmountC || 0
    },
    onMonthFinanceChange(obj) {
      this.monthFinanceList = obj.monthlyFinanceDataVoList || []
      this.projectPaymentAmountMnth = obj.projectPaymentAmount || 0
      this.projectPaymentAmountCMnth = obj.projectPaymentAmountC || 0
    },
    getMnthFinance(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 1) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 2) {
              sums[index] = Number(this.projectPaymentAmountMnth.toFixed(2)).toLocaleString()
            } else if (index === 5) {
              sums[index] = Number(this.projectPaymentAmountCMnth.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        } else if (index > 1) {
          //页面分别统计 处理
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            if (index === 3) {
              sums[index] = Number(this.projectPaymentAmount.toFixed(2)).toLocaleString()
            } else if (index === 6) {
              sums[index] = Number(this.projectPaymentAmountC.toFixed(2)).toLocaleString()
            } else {
              sums[index] = Number(sums[index].toFixed(2)).toLocaleString()
            }
          } else {
            sums[index] = '' //如果列的值有一项不是数字，就显示这个自定义内容
          }
        }
      })

      return sums
    },
    getType() {
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.yearManageYear = dictVal
          this.sectionInfo.monthFinanceYear = dictVal
          this.sectionInfo.addBusinessYear = dictVal
          this.init(dictVal)
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE', true)
        .then(res => {
          this.dictData.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
